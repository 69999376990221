// EmpanadaService.ts
export type Empanada = {
    id: string;
    name: string;
    imagePrimary: string;
    images: string[];
    description: string;
    dozenPrice: number;
    unitPrice: number;
    dealPrice: number;
    stock: number;
};

export const getEmpanadas = async (): Promise<Empanada[]> => {
    const response = await fetch('https://api.entremigasyrepulgues.com.ar/api/empanadas');
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
};
