import React, {useEffect, useState} from "react";
import {Empanada, getEmpanadas} from "../../../Services/EmpanadasService";
import {Container, Row, Col, Card, Carousel} from 'react-bootstrap';
import './Empanadas.css';

const Empanadas = () => {
    const [empanadas, setEmpanadas] = useState<Empanada[]>();

    useEffect(() => {
        getEmpanadas().then((empanadas) => {
            setEmpanadas(empanadas)
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <Container>
            <h1>Empanadas Congeladas</h1>
            {empanadas && empanadas.map((empanada, index) => (
                <Row key={index} className="mb-3">
                    <Col md={12}>
                        <Card className="border-0 card-shadow">
                            <div className={`d-flex ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
                                <Carousel slide={false} indicators={false}>
                                    {empanada.images.map((image, imageIndex) => (
                                        <Carousel.Item key={imageIndex} >
                                            <img className="d-block w-100 img-carousel" src={image}
                                                 alt={`Slide ${imageIndex}`}/>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                                <Card.Body>
                                    <Card.Title>
                                        {empanada.name}
                                        <span className={empanada.dealPrice > 0 ? 'dealOldPrice' : 'price'}>(${empanada.dozenPrice} Doc.)</span>
                                        {empanada.dealPrice > 0 && <p><span className="deal"> ¡OFERTA! </span><span className="dealPrice"> ${empanada.dealPrice} Doc.</span></p>}
                                        {empanada.stock <= 0 && <span className="withoutStock"> En Producción!</span>}
                                        {empanada.stock > 0 && empanada.stock < 24 && <span className="lowStock"> Quedan pocas!</span>}
                                    </Card.Title>
                                    <Card.Text>
                                        {empanada.description.split('\n').map((line, lineIndex) => (
                                            <p key={lineIndex}>{line}</p>
                                        ))}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                        </Card>
                    </Col>
                </Row>
            ))}
            <Row className="mb-3">
                <Card className="border-0 card-shadow">
                    <Card.Body>
                        <Card.Title>Todos los productos se elaboran con:</Card.Title>
                        <Card.Text>
                            <p>Carne de primera calidad, Carniceria El Vikingo.</p>
                            <p>Verduras frescas, Verduleria La Boutique.</p>
                            <p>Especias de primera calidad y 100% Naturales.</p>
                            <p>Tapas 100% Artesanales.</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
};

export default Empanadas;