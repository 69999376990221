import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import "./Header.css";

const Header = () => {
    return (
        <Navbar  expand="lg" className="flex-column">
            <div className="d-flex align-items-center">
                <Navbar.Brand href="/">
                    <img className="logo" src={process.env.PUBLIC_URL + '/Logo.png'} alt="Entre Migas Y Repulgues" />
                </Navbar.Brand>
                <h1 className="display-2 title">Entre Migas Y Repulgues</h1>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="w-100">
                <Nav className="mr-auto bg-grey w-100">
                    <NavDropdown title="Productos" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/empanadas">Empanadas Congeladas</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link as={Link} to="/pedidos">Pedidos</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;