import './App.css';
import { Analytics } from '@vercel/analytics/react';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './header/Header';
import Empanadas from './pages/empanadas/Empanadas';
import Footer from './footer/Footer';
import Orders from './pages/orders/Orders';

const App = () => {
    return (
        <Router>
            <div>
                <Header />
                <main>
                    <Routes>
                        <Route path="/" element={<Empanadas />} />
                        <Route path="/pedidos" element={<Orders />} />
                        <Route path="/empanadas" element={<Empanadas />} />
                    </Routes>
                </main>
                <Footer />
            </div>
            <Analytics />
        </Router>
    );
};

export default App;