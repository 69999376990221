import {Empanada} from "./EmpanadasService";

export class OrderItem {
    empanada: Empanada;
    dozen: number;
    units: number;

    public getPrice(): number {
        const halfDozenPrice = this.empanada.dozenPrice / 2;
        const halfDozen = Math.floor(this.units / 6);
        const units = this.units % 6;
        return (this.dozen * this.empanada.dozenPrice)
            + (halfDozen * halfDozenPrice)
            + (units * this.empanada.unitPrice);
    }

    public getDealPrice(): number {
        const dozenPrice = this.empanada.dealPrice ?? this.empanada.dozenPrice;
        const halfDozenPrice = dozenPrice / 2;
        const halfDozen = Math.floor(this.units / 6);
        const units = this.units % 6;
        return (this.dozen * dozenPrice)
            + (halfDozen * halfDozenPrice)
            + (units * this.empanada.unitPrice);
    }

    constructor(empanada: Empanada, dozen: number, units: number) {
        this.empanada = empanada;
        this.dozen = dozen;
        this.units = units;
    }
}

export type Order = {
    total: number;
    items: OrderItem[];
}


class OrderService {
    static getOrder(items: OrderItem[]): Order {
        const resultItems = items.filter(
            item => item.dozen > 0 || item.units > 0).map(
            item => {
                return item
            });
        return {
            total: resultItems.reduce((total, item) =>
                total + (
                    item.empanada.dealPrice ? item.getDealPrice() : item.getPrice()
                ), 0),
            items: resultItems
        };
    }

    static getEmptyOrder() {
        return {
            total: 0,
            items: [],
        }
    }

    static confirmOrder(order: Order) {
        const whatsappMessage = `Hola, quisiera hacer un pedido de: \n
        ${order.items.map(item => {
                let text = `${item.dozen} docena(s)`;
                if (item.units > 0) {
                    text += ` y ${item.units} unidad(es)`;
                }
                text += ` de ${item.empanada.name}`;
                return text;
            }
        ).join('\n')}\nTotal: $${order.total}`;
        window.open(`https://wa.me/543543651756?text=${encodeURIComponent(whatsappMessage)}`, '_blank');

    }
}

export default OrderService;