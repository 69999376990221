import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p></p>
            <p>© 2023 Entre Migas Y Repulgues</p>
        </footer>
    );
};

export default Footer;