import './OrderDetail.css';
import React from 'react';
import {Modal, ListGroup, ListGroupItem, Card, Row, Col, Button} from 'react-bootstrap';
import {Order} from '../../../../Services/OrdersService';

type OrderDetailProps = {
    order: Order;
    show: boolean;
    onHide: () => void;
    onConfirm: () => void;
}

const OrderDetail: React.FC<OrderDetailProps> = ({ order, show, onHide, onConfirm }) => {

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Detalles de la Orden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {order.items.map((item, index) => (
                        item.dozen > 0 || item.units > 0 ? (
                            <ListGroupItem key={index}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>{item.empanada.name}</Card.Title>
                                        <Row>
                                            {item.dozen > 0 &&
                                                <Col>
                                                    <Card.Text>Docenas: {item.dozen}</Card.Text>
                                                </Col>
                                            }
                                            {item.units > 0 &&
                                                <Col>
                                                    <Card.Text>Unidades: {item.units}</Card.Text>
                                                </Col>
                                            }
                                            <Col>
                                                {item.empanada.dealPrice ? (
                                                    <>
                                                        <Card.Text className="orderDealPrice">Precio: ${item.getPrice()}</Card.Text>
                                                        <Card.Text>Precio: ${item.getDealPrice()}</Card.Text>
                                                    </>
                                                ) : (
                                                    <Card.Text>Precio: ${item.getPrice()}</Card.Text>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </ListGroupItem>
                        ) : null
                    ))}
                    <ListGroupItem>
                        <strong>Total: ${order.total}</strong>
                    </ListGroupItem>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button className="bg-orange border-0" onClick={onConfirm}>Enviar Pedido</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OrderDetail;