import './Orders.css';
import React, {useEffect, useState} from 'react';
import OrderCard from './components/OrderCard';
import OrderDetail from './components/OrderDetail';
import {Empanada, getEmpanadas} from '../../../Services/EmpanadasService';
import OrdersService, {Order, OrderItem} from '../../../Services/OrdersService';
import {Container, Row, Col, Button} from 'react-bootstrap';

const Orders = () => {
    const [empanadas, setEmpanadas] = useState<Empanada[]>();

    useEffect(() => {
        getEmpanadas().then((empanadas) => {
            setEmpanadas(empanadas)
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const [orderUnits, setOrderUnits] = useState<OrderItem[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [order, setOrder] = useState<Order>(OrdersService.getEmptyOrder());

    const onCardChange = (empanada: Empanada, dozen: number, units: number) => {
        setOrderUnits(prevOrderUnits => {
            const index = prevOrderUnits.findIndex(orderUnit => orderUnit.empanada.name === empanada.name);
            if (index !== -1) {
                const newOrderUnits = [...prevOrderUnits];
                newOrderUnits[index] = new OrderItem(empanada, dozen, units);
                return newOrderUnits;
            } else {
                return [...prevOrderUnits, new OrderItem(empanada, dozen, units)];
            }
        });
    }

    const viewOrder = () => {
        const confirmedOrder = OrdersService.getOrder(orderUnits);
        setOrder(confirmedOrder);
        setShowModal(true);
    }

    return (
        <Container>
            <Row>
                {empanadas && empanadas.map((empanada) => (
                    <Col md={4} key={empanada.id}>
                        <OrderCard
                            empanada={empanada}
                            onCardChange={onCardChange}
                        />
                    </Col>
                ))}
            </Row>
            <div className="d-flex justify-content-center">
                <Button className='bg-orange btn-lg border-0' onClick={viewOrder}>Ver Pedido</Button>
            </div>
            {order && <OrderDetail
                show={showModal}
                onHide={() => setShowModal(false)}
                onConfirm={() => {
                        setOrderUnits([]);
                        setShowModal(false);
                        OrdersService.confirmOrder(order);
                    }
                }
                order={order}/>}
        </Container>
    );
}

export default Orders;